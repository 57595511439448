import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Footer = ({ children }) => {
  return (
    <Container>
      <div>© Programme</div>
      <div>
        <Link to="/terms">Terms</Link>
        <Link to="/privacy">Privacy</Link>
        <a rel="noreferrer" target="_blank" href="https://www.instagram.com/programme.app">
          Instagram
        </a>
      </div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 100vw !important;
  height: 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
  position: absolute;
  bottom: 0;

  &,
  a {
    font-size: 16px;
    text-transform: uppercase;
    font-family: DiatypeMono;
    color: var(--light-grey);
  }

  a {
    margin-inline: 0.5em;
    transition: 0.2s all;

    &:hover {
      color: var(--white);
    }
  }

  @media (max-width: 36em) {
    justify-content: center;
    div:first-child {
      display: none;
    }
  }

  @media (max-width: 48em) {
    display: none;
  }
`

export default Footer
