import React, { useEffect } from "react"
import { motion } from "framer-motion"
import "./layout.css"

import NavBar from "./NavBar"
import TabBar from "./TabBar"
import Footer from "./Footer"

const Layout = props => {
  function inIframe() {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  useEffect(() => {
    if (inIframe()) {
      const html = document.getElementsByTagName("html")[0]
      html.style.background = "transparent"
    }
  }, [])

  return inIframe() ? props.children : (
    <>
      <NavBar
        theme="dark"
        float
        muted={props.path === "/" || props.path.includes("exercises/")}
        accent={props.path.includes("resources/")}
      />
      {/* <AnimatePresence exitBeforeEnter> */}
        <motion.main
          key={`${props.path}-page`}
          transition={{ duration: 0.2 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {props.children}
        </motion.main>
      {/* </AnimatePresence> */}
      {props.path === "/" ? <Footer /> : null}
      <TabBar path={props.path} />
    </>
  )
}

export default Layout
