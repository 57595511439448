import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const Menu = ({ isMenuOpen, setIsMenuOpen }) => {
  const close = () => setIsMenuOpen(false);
  return (
    <MenuContainer
      variants={{
        open: {
          opacity: 1,
          pointerEvents: 'auto'
        },
        closed: {
          opacity: 0,
          pointerEvents: 'none'
        }
      }}
      transition={{ duration: 0.2 }}
      animate={isMenuOpen ? 'open' : 'closed'}
      initial='closed'
    >
      <Section>
        <MenuItem to="/" onClick={close}>
          Programme
        </MenuItem>
        <MenuItem to="/exercises" onClick={close}>
          Exercises
        </MenuItem>
        {/* <MenuItem to="/workouts" onClick={close}> */}
        {/*   Workouts */}
        {/* </MenuItem> */}
        <MenuItem to="/resources" onClick={close}>
          Resources
        </MenuItem>
      </Section>
      <Section style={{ opacity: 0.5 }}>
        <MenuItem to="/privacy" onClick={close}>
          Privacy
        </MenuItem>
        <MenuItem to="/terms" onClick={close}>
          Terms
        </MenuItem>
      </Section>
    </MenuContainer>
  )
}

const MenuContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100dvh;
  height: 100dvh;
  padding: 80px 20px 20px;
  background: var(--dark-grey);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Section = styled.div``

const MenuItem = styled(Link)`
  color: white; 
  font-size: 24px;
  width: 100%;
  display: block;
  margin-block: 0.5em;
`

const TabBar = ({ path }) => {
  const [position, setPosition] = useState(0)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    if (path === "/") setPosition(0)
    if (path.includes("exercises")) setPosition(1)
    // if (path.includes("workouts")) setPosition(2)
    if (path.includes("resources")) setPosition(2)
  }, [path, setPosition])

  return (
    <Container>
      <Option to="/" selected={position === 0}>
        Programme
      </Option>
      <Divider />
      <Option to="/exercises" selected={position === 1}>
        Exercises
      </Option>
      <Divider />
      {/* <Option to="/workouts" selected={position === 2}> */}
      {/*   Workouts */}
      {/* </Option> */}
      <Divider />
      <Option to="/resources" selected={position === 2}>
        Resources
      </Option>
      <Background position={position} />
      <Icon onClick={() => setIsMenuOpen(curr => !curr)}>
        {
          !isMenuOpen ?
            <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1H17" stroke="white" strokeWidth="2" strokeLinecap="round"/>
              <path d="M1 9H17" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          :
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999023 0.999023L13.0002 13.0002" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            <path d="M0.999023 13.001L13.0002 0.999754" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        }
      </Icon>
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  height: 44px;
  border-radius: 40px;
  z-index: 200;
  display: flex;
  width: 364px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  background: #1E1E1E;
  border-radius: 12px;
  transition: 0.3s all ease-in-out;
  @media (max-width: 48em) {
    width: 40px;
    height: 40px;
    justify-content: center;
  }
`

const Icon = styled.div`
  display: none; 
  @media (max-width: 48em) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 10000;
  }
`

const Divider = styled.div`
  height: 50%;
  width: 1px;
  background: #0a0a0a;
  @media (max-width: 48em) {
    display: none;
  }
`

const Option = styled(Link)`
  display: block;
  height: 40px;
  padding: 11px 0;
  z-index: 1;
  color: ${({ selected }) => (selected ? "var(--black)" : "var(--white)")};
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  line-height: 1;
  width: 120px;
  text-align: center;
  transition: 0.2s all;
  @media (max-width: 48em) {
    display: none;
  }
`

const Background = styled(motion.div)`
  background: var(--off-white);
  height: 40px;
  width: 120px;
  position: absolute;
  transition: 0.2s all;
  left: ${({ position }) => 2 + position * 120}px;
  border-radius: 10px;
  z-index: 0;
  @media (max-width: 48em) {
    display: none;
  }
`

export default TabBar
