import React from "react"
import ReactDOM from 'react-dom'
import Layout from "./src/components/Layout"

export function wrapPageElement({ element, props }) {
  return <Layout path={props.path}>{element}</Layout>;
}

// this is a hack to fix missing styles on refresh in production
// reference: https://github.com/gatsbyjs/gatsby/issues/17676#issuecomment-535796737
export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}
